import { ReactNode, useContext } from "react";
import { Navigate } from "react-router-dom";

import Layout from "components/Navigation/Layout";
import Path from "enums/Path";
import { Enabled } from "enums/Enabled";
import { AuthContext } from "providers/authContext";

const PrivateRoute = ({
  children,
  burger = false,
  upload = false,
}: {
  children: ReactNode;
  burger?: boolean;
  upload?: boolean;
}) => {
  const { user, enabled } = useContext(AuthContext);

  if (user) {
    if (enabled && enabled !== Enabled.APPROVED) {
      return <Navigate to={Path.ThanksForApplying} />;
    }
    return (
      <Layout burger={burger} upload={upload}>
        {children}
      </Layout>
    );
  }

  return <Navigate to={Path.SignIn} />;
};

export default PrivateRoute;
