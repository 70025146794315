import * as yup from "yup";

export const signUpUserFields = [
  {
    name: "email",
    type: "email",
    placeholder: "EMAIL",
  },
  {
    name: "password",
    type: "password",
    placeholder: "CREATE A PASSWORD",
  },
  {
    name: "confirmPassword",
    type: "password",
    placeholder: "CONFIRM PASSWORD",
  },
  {
    name: "firstName",
    type: "text",
    placeholder: "FIRST NAME",
  },
  {
    name: "lastName",
    type: "text",
    placeholder: "LAST NAME",
  },
  {
    name: "phoneNumber",
    type: "text",
    placeholder: "PHONE NUMBER",
  },
];

export const validationSchema = () =>
  yup.object().shape({
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),
    password: yup.string().required("Password is required").min(8),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    phoneNumber: yup
      .string()
      .matches(
        /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im,
        "Phone number is not valid"
      )
      .required("Phone Number is required"),
  });
