import Paragraph from "components/Common/Paragraph";
import formatNumber from "utils/formatNumber";

import classes from "./WalletTrack.module.scss";

type TrackProps = {
  title: string;
  timesPlayed: number;
  trackImage: string;
  doseTokens: number;
};

const WalletTrack = ({
  timesPlayed,
  title,
  trackImage,
  doseTokens = 0,
}: TrackProps) => (
  <div className={classes.walletTrackContainer}>
    <div className={classes.walletTrackRow}>
      <img className={classes.walletTrackImage} src={trackImage} alt={title} />
      <div>
        <Paragraph className={classes.walletTimesPlayed} size="xl">
          {formatNumber(timesPlayed)}
        </Paragraph>
        <Paragraph className={classes.walletNumberSubtitle} size="xxs">
          PLAYS
        </Paragraph>
      </div>
      <Paragraph className={classes.walletTrackEqual} size="xl">
        =
      </Paragraph>
      <div>
        <Paragraph className={classes.walletTokens} size="xl">
          {formatNumber(doseTokens)}
        </Paragraph>
        <Paragraph className={classes.walletNumberSubtitle} size="xxs">
          TOKENS
        </Paragraph>
      </div>
    </div>
    <Paragraph className={classes.walletTrackTitle} size="xs">
      {title}
    </Paragraph>
  </div>
);

export default WalletTrack;
