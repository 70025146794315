import { useState, useContext, useCallback, useEffect } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { FirebaseError } from "firebase/app";

import beautifyError from "utils/beautifyError";
import MultipleStepForm from "../../Common/MultiStepForm";
import SignUpArtistForm from "../SignUpArtistForm";
import SignUpUserForm from "../SignUpUserForm";
import { SignUpFormContext } from "../../../providers/signUpFormContext";
import { signUp } from "../../../services/AuthService";
import Path from "../../../enums/Path";
import ErrorMessage from "../../Common/ErrorMessage";

export type SignUpDataType = {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  artistName: string;
  country: string;
  city: string;
  spotify: string;
  appleMusic: string;
  website: string;
};

const SignUpForm = () => {
  const navigate = useNavigate();
  const { signUpData } = useContext(SignUpFormContext);
  const [error, setError] = useState("");
  const steps = [SignUpUserForm, SignUpArtistForm];

  const { mutate, isLoading } = useMutation("signUp", {
    mutationFn: (data: SignUpDataType) => signUp(data),
    onSuccess: () => {
      navigate(Path.ThanksForApplying);
    },
    onError: (mutationError) => {
      setError(beautifyError(mutationError as FirebaseError));
    },
  });

  const onSubmit = useCallback(
    (signUpNewData: SignUpDataType | undefined) => {
      if (signUpNewData) mutate(signUpNewData);
    },
    [mutate]
  );

  useEffect(() => {
    if (signUpData?.artistName) onSubmit(signUpData);
  }, [onSubmit, signUpData]);

  return (
    <>
      <MultipleStepForm steps={steps} />

      {
        // TODO: Add loading spinner
        isLoading && <div>Loading...</div>
      }
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
};

export default SignUpForm;
