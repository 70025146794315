import { createContext, ReactNode, useEffect, useMemo, useState } from "react";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";

import Spinner from "components/Common/Spinner";

import { doc, getDoc } from "firebase/firestore";
import { app, db } from "../../firebase";

export const AuthContext = createContext<{
  user?: User;
  setUser: (prevState?: User) => void;
  pending: boolean;
  enabled: string;
  setEnabled: (prevState: string) => void;
}>({
  user: undefined,
  setUser: () => {},
  pending: true,
  setEnabled: () => {},
  enabled: "",
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [pending, setPending] = useState(true);
  const [enabled, setEnabled] = useState("");

  const getArtistProfile = async (uid: string) => {
    const artistRef = await doc(db, "artists", uid);
    const artistProfile = await getDoc(artistRef);
    const artist = artistProfile.data();

    if (artist) {
      setEnabled(artist.enabled);
    }
  };

  useEffect(() => {
    const auth = getAuth(app);

    onAuthStateChanged(auth, (authUser) => {
      setUser(authUser || undefined);
      setPending(false);

      if (authUser && authUser?.uid) {
        getArtistProfile(authUser?.uid);
      }
    });
  }, []);

  const values = useMemo(
    () => ({
      user,
      setUser,
      pending,
      enabled,
      setEnabled,
    }),
    [pending, user, enabled]
  );

  if (pending) {
    return <Spinner color="black" size={60} />;
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};
