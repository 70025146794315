/* eslint-disable @typescript-eslint/no-explicit-any */
const customStyles = {
  control: () => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    border: "none",
    borderBottom: "0.063rem solid #ffacac",
    fontSize: "0.875rem",
    fontWeight: "bolder",
    padding: "0.188rem 0",
    backgroundColor: "#ffffff",
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    opacity: state.isDisabled ? 0.5 : 1,
    transition: "opacity 300ms",
    display: "flex",
    justifyContent: "flex-start",
    heigth: "1.313rem",
    color: "#000000",
    fontWeight: "bolder",
  }),
  placeholder: (defaultStyles: any) => ({
    ...defaultStyles,
    color: "#000000",
    display: "flex",
    justifyContent: "flex-start",
  }),
  valueContainer: () => ({
    display: "flex",
    alignItems: "center",
  }),
};

export default customStyles;
