import ClipLoader from "react-spinners/ClipLoader";

import classes from "./SpinnerStyle.module.scss";

type Size = 60 | 30 | 10;

type Color = "white" | "black";

export type SpinnerProps = {
  size?: Size;
  color?: Color;
  className?: string;
};

const Spinner = ({
  color = "white",
  size = 30,
  className = classes.spinnerTop,
}: SpinnerProps) => (
  <div className={className}>
    <ClipLoader color={color} size={size} />
  </div>
);
export default Spinner;
