import { useNavigate } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import classNames from "classnames";

import Paragraph from "components/Common/Paragraph";
import { links } from "utils/accountLinks/accountLinks";
import Path from "enums/Path";
import { EditFormTitles } from "enums/editFormTitles";

import classes from "./AccountOptions.module.scss";

const AccountOptions = () => {
  const { boot, show } = useIntercom();
  const navigate = useNavigate();

  const lunchIntercom = () => {
    boot();
    show();
  };

  const onClickComponentHandler = (title: string) => {
    if (title === EditFormTitles.TalkToUs) return lunchIntercom;
    if (title === EditFormTitles.DeleteAccount)
      return () => {
        navigate(Path.ConfirmDeactivateAccount);
      };
    return null;
  };

  return (
    <div className={classes.linksContainer}>
      {links.map(({ Component, title, ...fields }) => (
        <Component
          key={title}
          onClick={onClickComponentHandler(title)}
          {...fields}
        >
          <Paragraph
            size="xs"
            className={classNames({
              [classes.deleteAccount]: title === "Delete Account",
            })}
          >
            {title}
          </Paragraph>
        </Component>
      ))}
    </div>
  );
};

export default AccountOptions;
