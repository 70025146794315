import * as yup from "yup";

import FormControl from "../../components/Common/FormControl";
import ImageFormControl from "../../components/Common/ImageFormControl";
import AudioFormControl from "../../components/Common/AudioFormControl";
import ToggleFormControl from "../../components/Common/ToggleFormControl";

export const newReleaseFields = [
  {
    name: "audio",
    type: "file",
    placeholder: "ADD TRACK",
    formControl: AudioFormControl,
  },
  {
    name: "image",
    type: "file",
    placeholder: "ADD IMAGE",
    formControl: ImageFormControl,
  },
  {
    name: "name",
    type: "text",
    placeholder: "NAME OF RELEASE",
    formControl: FormControl,
  },
  {
    name: "explicitContent",
    type: "checkbox",
    placeholder: "Explicit Content",
    label: "Explicit Content",
    formControl: ToggleFormControl,
  },
];

export const validationSchema = () =>
  yup.object().shape({
    audio: yup.mixed().required("Track is required"),
    image: yup.mixed().required("Image is required"),
    name: yup.string().required("Name is required"),
    explicitContent: yup.boolean().default(false),
  });
