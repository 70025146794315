import * as yup from "yup";

import FormControl from "components/Common/FormControl";
import SelectFormControl from "components/Common/SelectFormControl";

import { countriesList } from "utils/global/countriesList";

const validatePasswordField = (value: string) => value?.length > 0;

export const editPasswordFields = [
  {
    name: "email",
    type: "text",
    labelClassName: "Update Email",
    content: "email",
    disabled: true,
    formControl: FormControl,
  },
  {
    name: "password",
    type: "password",
    label: "Update Password",
    content: "password",
    autocomplete: "new-password",
    formControl: FormControl,
  },
  {
    name: "confirmPassword",
    type: "password",
    label: "Confirm New Password",
    content: "password",
    conditionToShow: {
      validator: validatePasswordField,
      fieldToCheck: "password",
    },
    formControl: FormControl,
  },
  {
    name: "currentPassword",
    type: "password",
    label: "Enter Current Password",
    content: "password",
    conditionToShow: {
      validator: validatePasswordField,
      fieldToCheck: "password",
    },
    formControl: FormControl,
  },
  {
    name: "firstName",
    type: "text",
    label: "Update First Name",
    content: "firstName",
    formControl: FormControl,
  },
  {
    name: "lastName",
    type: "text",
    label: "Update Last Name",
    content: "lastName",
    formControl: FormControl,
  },
  {
    name: "phoneNumber",
    type: "text",
    label: "Update Phone Number",
    content: "phoneNumber",
    formControl: FormControl,
  },
  {
    name: "artistName",
    type: "text",
    label: "Artist Name",
    content: "artistName",
    formControl: FormControl,
  },
  {
    name: "country",
    type: "text",
    label: "Country",
    options: countriesList,
    placeholder: "SELECT COUNTRY",
    formControl: SelectFormControl,
  },
  {
    name: "city",
    label: "City",
    type: "text",
    content: "city",
    formControl: FormControl,
  },
  {
    name: "spotify",
    label: "Spotify",
    type: "text",
    content: "spotify",
    formControl: FormControl,
  },
  {
    name: "appleMusic",
    label: "Apple Music",
    type: "text",
    content: "appleMusic",
    formControl: FormControl,
  },
  {
    name: "website",
    label: "Social Media (Instagram, TikTok…)",
    type: "text",
    content: "website",
    formControl: FormControl,
  },
];

export const validationSchema = () =>
  yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    phoneNumber: yup
      .string()
      .matches(
        /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im,
        "Phone number is not valid"
      )
      .required("Phone Number is required"),
    password: yup.string(),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
    artistName: yup.string().required("Artist Name is required"),
    country: yup.string().required("Country is required"),
    city: yup.string().required("City is required"),
    website: yup
      .string()
      .test(
        "socialMedia",
        "Please enter your social media url",
        (value) =>
          !!value?.match(
            /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
          )
      ),
    spotify: yup
      .string()
      .test(
        "oneOfRequired",
        "Please enter at least one audio service url",
        (value, context) =>
          (context.parent.appleMusic.match(
            /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
          ) &&
            context.parent.appleMusic.includes("apple.com")) ||
          (value?.includes("spotify.com") &&
            value.match(
              /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
            ))
      ),
    appleMusic: yup
      .string()
      .test(
        "oneOfRequired",
        "Please enter at least one audio service url",
        (value, context) =>
          (context.parent.spotify.match(
            /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
          ) &&
            context.parent.spotify.includes("spotify.com")) ||
          (value?.match(
            /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
          ) &&
            value.includes("apple.com"))
      ),
  });
