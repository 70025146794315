import { FirebaseError } from "firebase/app";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getAuth } from "firebase/auth";
import { yupResolver } from "@hookform/resolvers/yup";

import Title from "components/Common/Title";
import AccountOptions from "components/AccountOptions";
import Paragraph from "components/Common/Paragraph";
import Form from "components/Common/Form";
import Button from "components/Common/Button";
import Toast from "components/Common/Toast";

import {
  editPasswordFields,
  validationSchema,
} from "utils/formFields/editAccountFields";
import beautifyError from "utils/beautifyError";

import { getUser } from "services/AuthService";
import { updateUserData } from "services/UpdateUserData";
import UserCredentials from "types/UserCredentials";

import classes from "./AccountForm.module.scss";

const AccountEditForm = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<UserCredentials>({});
  const [editStatus, setEditStatus] = useState("");

  const handleGetUser = async () => {
    try {
      setLoading(true);
      const auth = getAuth();

      if (auth.currentUser) {
        const { uid } = auth.currentUser;
        const userFields: UserCredentials = await getUser(uid);

        setUser(userFields);
      }
    } catch (getUserError) {
      setError(beautifyError(getUserError as FirebaseError));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetUser();
  }, []);

  const onSubmitHandler = useCallback(
    async (data: UserCredentials) => {
      try {
        setLoading(true);
        await updateUserData(user, data).then(() => {
          setEditStatus("User successfully updated!");
          setLoading(false);
          setTimeout(() => {
            setEditStatus("");
          }, 1000);
        });
      } catch (updateUserError: unknown) {
        const typedError = updateUserError as FirebaseError;
        const beautifiedError = beautifyError(typedError);

        if (beautifiedError === "Unknown error. Please, try again.") {
          setError(typedError.message);
        } else {
          setError(beautifiedError);
        }

        setLoading(false);
        setTimeout(() => {
          setError("");
        }, 1000);
      }
    },
    [user]
  );

  const useFormProps = useMemo(
    () => ({
      resolver: yupResolver(validationSchema()),
      mode: "all",
      defaultValues: {
        country: user?.country,
      },
    }),
    [user]
  );

  return (
    <div>
      {Object.keys(user).length > 0 && (
        <Form
          onSubmit={onSubmitHandler}
          useFormProps={useFormProps}
          className={classes.form}
        >
          {editPasswordFields.map(({ formControl: FormControl, ...field }) => (
            <>
              {field.name === "artistName" && (
                <Title size="xs" className={classes.artistTitle}>
                  Artist Profile
                </Title>
              )}
              <FormControl
                key={field.name}
                labelClassName={classes.label}
                inputClassName={classes.input}
                defaultValue={user[field.content as keyof typeof user]}
                className={classes.inputContainer}
                {...field}
              />
            </>
          ))}
          <div className={classes.buttonContainer}>
            <Button loading={loading} type="submit" className={classes.button}>
              <Paragraph size="m">UPDATE</Paragraph>
            </Button>
          </div>
        </Form>
      )}
      <AccountOptions />
      {error && <Toast text={error} id="new-release-error-creation" />}
      {editStatus && (
        <Toast
          type="success"
          text={editStatus}
          id="account-succesfully-modified"
        />
      )}
    </div>
  );
};

export default AccountEditForm;
