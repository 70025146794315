import classNames from "classnames";

import Title from "components/Common/Title";
import formatNumber from "utils/formatNumber";

import classes from "./ReleaseBox.module.scss";

type TrackProps = {
  title: string;
  listeners: number;
  image: string;
};

const ReleaseBox = ({ title, listeners, image }: TrackProps) => (
  <div className={classes.releaseBox}>
    <img className={classes.image} src={image} alt={`Track: ${title}`} />
    <div className={classes.textLayer}>
      <Title
        className={classNames(classes.title, {
          [classes.littleTitle]: title.split(" ").length > 11,
        })}
        size="xl"
      >
        {title}
      </Title>

      <Title className={classes.listeners} size="xl">
        {formatNumber(listeners)} LISTENERS
      </Title>
    </div>
  </div>
);

export default ReleaseBox;
