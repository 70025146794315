/* eslint-disable react/button-has-type */
import classNames from "classnames";

import Link from "components/Navigation/Link";
import Spinner from "components/Common/Spinner";

import classes from "./Button.module.scss";

type Size = "L" | "S";

export type Kind =
  | "primary"
  | "secondary"
  | "tertiary"
  | "minimal"
  | "cancellation";

type Type = "button" | "submit" | "reset";

export type ButtonProps = {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  kind?: Kind;
  loading?: boolean;
  onClick?: () => void;
  size?: Size;
  type?: Type;
  isFullWidth?: boolean;
  upperCase?: boolean;
  icon?: string;
  iconClass?: string;
  iconAlt?: string;
  iconLink?: string;
  buttonLink?: string;
  linkText?: string;
};

const Button = ({
  size = "L",
  kind = "primary",
  type = "button",
  disabled = false,
  loading = false,
  isFullWidth = false,
  children = null,
  onClick = () => {},
  className = "",
  upperCase = false,
  icon = undefined,
  iconClass = "",
  iconAlt = "",
  iconLink = "/",
  buttonLink,
  linkText,
}: ButtonProps) => (
  <button
    type={type}
    className={classNames(
      classes.button,
      classes[size],
      className,
      classes[kind],
      {
        [classes.loading]: loading,
        [classes.isFullWidth]: isFullWidth,
        [classes.disabled]: disabled,
        [classes.upperCase]: upperCase,
      }
    )}
    onClick={onClick}
    disabled={disabled}
  >
    {icon && (
      <Link to={iconLink}>
        <img
          src={icon}
          className={classNames(classes.icon, iconClass)}
          alt={iconAlt}
        />
      </Link>
    )}

    {buttonLink && (
      <Link to={buttonLink} className={classes.link}>
        {linkText}
      </Link>
    )}

    {loading ? (
      <Spinner color="white" size={10} className={classes.spinner} />
    ) : (
      children
    )}
  </button>
);

export default Button;
