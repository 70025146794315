import Path from "enums/Path";
import Layout from "../../components/Navigation/Layout";
import Paragraph from "../../components/Common/Paragraph";
import Title from "../../components/Common/Title";
import confirmation from "../../assets/images/confirmation.png";

import classes from "./ThanksForApplying.module.scss";

const ThanksForApplying = () => (
  <Layout background headerLogoPath={Path.SignIn}>
    <div className={classes.container}>
      <img src={confirmation} className={classes.image} alt="confirmation" />
      <Title size="xs" kind="h3" className={classes.title}>
        Thanks For Signing Up
      </Title>
      <Paragraph size="s" className={classes.thanksMessage}>
        Our team is checking your profile, and you will receive an email on your
        approval status.
      </Paragraph>
    </div>
  </Layout>
);

export default ThanksForApplying;
