import Paragraph from "components/Common/Paragraph";
import Title from "components/Common/Title";
import walletCoin from "assets/images/walletCoin.png";

import classes from "./WalletData.module.scss";

const WalletData = ({ doseTokens }: { doseTokens: number }) => (
  <div>
    <Paragraph className={classes.title} size="m">
      Wallet
    </Paragraph>
    <Paragraph className={classes.subTitle} size="s">
      Total of Dose tokens earned
    </Paragraph>
    <Title className={classes.tokens}>{doseTokens}</Title>
    <img src={walletCoin} className={classes.walletImg} alt="Coins" />
    <a
      className={classes.tokensInfo}
      href="https://help.dose.fm/en/articles/5951827-what-are-dose-points-for-drivers"
      target="_blank"
      rel="noreferrer"
    >
      What are Dose tokens?
    </a>
  </div>
);

export default WalletData;
