import { useEffect } from "react";

import SignInForm from "components/Forms/SingInForm";
import Title from "components/Common/Title";
import Paragraph from "components/Common/Paragraph";
import Layout from "components/Navigation/Layout";
import Link from "components/Navigation/Link";
import Path from "enums/Path";
import { logOut } from "services/AuthService";

import classes from "./SignIn.module.scss";

const SignIn = () => {
  const logSessionOut = async () => {
    await logOut();
  };
  useEffect(() => {
    logSessionOut();
  }, []);

  return (
    <Layout background>
      <div className={classes.container}>
        <Title kind="h3" size="xs">
          Welcome Back To Dose For Creators
        </Title>
        <Paragraph size="s" className={classes.welcome}>
          Distribute your music to the world.
        </Paragraph>
        <SignInForm />

        <Link className={classes.link} to={Path.SignUp}>
          Don’t Have An Account?
        </Link>
      </div>
    </Layout>
  );
};

export default SignIn;
