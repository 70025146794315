import classNames from "classnames";

import Button from "components/Common/Button";
import Link from "components/Navigation/Link";
import links from "utils/burguerMenuLinks";

import classes from "./BurguerMenuNav.module.scss";

const BurguerMenuNav = ({ onClick }: { onClick: () => void }) => (
  <div className={classNames(classes.menu)}>
    {links.map(({ title, path }) => (
      <Button key={title} kind="minimal" onClick={onClick}>
        <Link to={path} className={classes.navLink}>
          {title}
        </Link>
      </Button>
    ))}
  </div>
);

export default BurguerMenuNav;
