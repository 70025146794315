/* eslint-disable @typescript-eslint/no-explicit-any */
import { FirebaseError } from "firebase/app";

export const authErrors: any = {
  "auth/email-already-in-use": "Invalid credentials. Try again",
  "auth/email-already-exists": "Invalid credentials. Try again",
  "auth/invalid-email": "Invalid email. Try again",
  "auth/wrong-password": "Invalid credentials. Try again",
  "auth/too-many-requests": "Too many requests. Try again later.",
  "auth/invalid-password":
    "Invalid password. It must be a string with at least six characters.",
  "auth/invalid-credential": "Invalid credentials. Try again",
  "auth/user-not-found": "Invalid credentials. Try again",
  "auth/requires-recent-login":
    "Your last sign-in is not recent enough, please try to sign-in again",
};

export const storageError: any = {
  "storage/object-not-found": "Could not find the desired track",
  "storage/canceled": "You canceled the track upload operation",
  "storage/retry-limit-exceeded":
    "The maximum time limit for the operation with your track has been exceeded",
  "storage/server-file-wrong-size": "Try uploading your track again",
  "storage/unauthenticated":
    "User is unauthenticated, please authenticate and try again",
};

const beautifyError = (error: FirebaseError | (Error & { code: string })) =>
  authErrors[error.code] ??
  authErrors[error.message] ??
  storageError[error.code] ??
  storageError[error.message] ??
  "Unknown error. Please, try again.";

export default beautifyError;
