import classNames from "classnames";
import { useForm, FormProvider } from "react-hook-form";

import classes from "./Form.module.scss";

export type ValidationMode =
  | "onBlur"
  | "onChange"
  | "onSubmit"
  | "onTouched"
  | "all";

export type FormProps = {
  children: React.ReactNode;
  className?: string;
  onSubmit: (data: any) => void;
  useFormProps?: any;
  onFocus?: () => void;
};
const Form = ({
  children,
  onSubmit,
  className,
  useFormProps,
  onFocus,
}: FormProps) => {
  const formMethods = useForm(useFormProps);
  const { handleSubmit } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <form
        className={classNames(className, classes.form)}
        onSubmit={handleSubmit(onSubmit)}
        onFocus={onFocus}
      >
        {children}
      </form>
    </FormProvider>
  );
};

export default Form;
