import { useMemo } from "react";

import useMultipleStepFormProgress from "../../../hooks/useMultipleStepFormProgress";

type MultipleStepFormProps = {
  steps: React.ComponentType<any>[];
};

const MultipleStepForm = ({ steps }: MultipleStepFormProps) => {
  const [currentStep, goForward, goBack]: any = useMultipleStepFormProgress();

  const isFirst = useMemo(() => currentStep === 0, [currentStep]);

  const CurrentStep = steps[currentStep];

  const currentStepProps = useMemo(() => {
    const props: {
      goForward: () => void;
      goBack?: () => void;
    } = {
      goForward,
    };

    if (!isFirst) {
      props.goBack = goBack;
    }

    return props;
  }, [goBack, goForward, isFirst]);

  return <CurrentStep {...currentStepProps} />;
};

export default MultipleStepForm;
