/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, ReactNode, useState, useMemo } from "react";

export type SignUpData = {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  artistName: string;
  country: string;
  city: string;
  spotify: string;
  appleMusic: string;
  website: string;
};

export const SignUpFormContext = createContext<{
  signUpData?: SignUpData;
  setSignUpData: (prevState?: SignUpData) => void;
  error?: Error;
  setError: (prevState?: Error) => void;
  isLoading: boolean;
  setIsLoading: (prevState: boolean) => void;
}>({
  setSignUpData: () => {},
  setError: () => {},
  isLoading: false,
  setIsLoading: () => {},
});

const SignUpFormProvider = ({ children }: { children: ReactNode }) => {
  const [signUpData, setSignUpData] = useState<SignUpData>();
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState(false);

  const value = useMemo(
    () => ({
      signUpData,
      setSignUpData,
      error,
      setError,
      isLoading,
      setIsLoading,
    }),
    [error, isLoading, signUpData]
  );

  return (
    <SignUpFormContext.Provider value={value}>
      {children}
    </SignUpFormContext.Provider>
  );
};

export default SignUpFormProvider;
