import { useMemo, useCallback, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { FirebaseError } from "firebase/app";

import Form from "components/Common/Form";
import FormControl from "components/Common/FormControl";
import { signInFields, validationSchema } from "utils/formFields/signInFields";
import { signIn } from "services/AuthService";
import Link from "components/Navigation/Link";
import Button from "components/Common/Button";
import Toast from "components/Common/Toast";
import Path from "enums/Path";
import beautifyError from "../../../utils/beautifyError";

import classes from "./SignInForm.module.scss";

type SignInSubmitProps = {
  email: string;
  password: string;
};

const SignInForm = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const useFormProps = useMemo(
    () => ({
      resolver: yupResolver(validationSchema()),
      mode: "all",
    }),
    []
  );

  const onSubmitHandler = useCallback(
    async ({ email, password }: SignInSubmitProps) => {
      try {
        setError("");
        await signIn(email, password);
        navigate(Path.Home);
      } catch (submitError) {
        setError(beautifyError(submitError as FirebaseError));
      }
    },
    [navigate]
  );

  return (
    <Form
      useFormProps={useFormProps}
      onSubmit={onSubmitHandler}
      className={classes.form}
    >
      <div className={classes.formContainer}>
        {signInFields.map((field) => (
          <FormControl
            key={field.name}
            {...field}
            inputClassName={classes.input}
          />
        ))}
        <Link to={Path.ForgotPassword} className={classes.forgotPassword}>
          Forgot Password?
        </Link>
      </div>
      <div className={classes.buttonContainer}>
        <Button type="submit" className={classes.button}>
          SIGN IN
        </Button>
      </div>
      {error && <Toast text={error} id="sign-in-form-error" />}
    </Form>
  );
};

export default SignInForm;
