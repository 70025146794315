import Track from "types/Track";
import WalletTrack from "./WalletTrack";

import classes from "./WalletTracks.module.scss";

const WalletTracks = ({ tracks }: { tracks: Track[] }) => (
  <div className={classes.walletTracksComponent}>
    {tracks.map(({ title, timesPlayed, artworkUrl, doseTokens }) => (
      <WalletTrack
        title={title}
        timesPlayed={timesPlayed}
        doseTokens={doseTokens}
        trackImage={artworkUrl}
        key={title}
      />
    ))}
  </div>
);

export default WalletTracks;
