import classes from "./ErrorMessage.module.scss";

export type ErrorMessageProps = {
  children?: React.ReactNode;
};

const ErrorMessage = ({ children = undefined }: ErrorMessageProps) => (
  <p className={classes.errorMessage}>{children}</p>
);

export default ErrorMessage;
