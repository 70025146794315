import { collection, getDocs } from "firebase/firestore";
import { db, auth } from "../firebase";

import Track from "../types/Track";

const getTracks: () => Promise<Array<Track>> = async () => {
  try {
    const docs = await getDocs(
      collection(db, `artists`, `${auth?.currentUser?.uid}`, "tracks")
    );
    const tracks = docs.docs.map((snapshot) => {
      const track = snapshot.data() as Track;
      track.id = snapshot.id;
      return track;
    });

    return tracks;
  } catch (err: unknown) {
    const error = err as Error;

    throw new Error(error.message);
  }
};

export default getTracks;
