import classNames from "classnames";

import Header from "components/Navigation/Header";
import Path from "enums/Path";

import classes from "./Layout.module.scss";

type HeaderProps = {
  children?: React.ReactNode;
  background?: boolean;
  burger?: boolean;
  upload?: boolean;
  headerLogoPath?: string;
  className?: string;
};

const Layout = ({
  children,
  background = false,
  burger = false,
  upload = false,
  headerLogoPath = Path.Home,
  className,
}: HeaderProps) => (
  <div
    className={classNames(classes.container, className, {
      [classes.background]: background,
    })}
  >
    <Header
      headerLogoPath={headerLogoPath}
      background
      burger={burger}
      upload={upload}
    />
    <main className={classes.main}>{children}</main>
  </div>
);
export default Layout;
