import { useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import Switch from "react-switch";

import Label from "../Label";

import classes from "./ToggleFormControl.module.scss";
import colors from "../../../styles/styles.module.scss";

type ToggleFormControlProps = {
  name: string;
};

const ToggleFormControl = ({ name }: ToggleFormControlProps) => {
  const [toggle, setToggle] = useState(false);

  const methods = useFormContext();
  const { control } = methods;

  const handleToggleChange = (event: any) => {
    setToggle(event);
  };

  return (
    <div className={classes.container}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, ref } }) => (
          <>
            <Label>Explicit Content</Label>
            <Switch
              onChange={(value: any) => {
                onChange(value);
                handleToggleChange(value);
              }}
              checked={toggle}
              ref={ref}
              className={classes.switch}
              onColor={colors.green}
              checkedIcon={false}
              uncheckedIcon={false}
            />
          </>
        )}
      />
    </div>
  );
};

export default ToggleFormControl;
