import Layout from "components/Navigation/Layout";
import Paragraph from "components/Common/Paragraph";
import Title from "components/Common/Title";
import confirmation from "assets/images/confirmationRelease.png";

import classes from "./ReceivedRelease.module.scss";

const ReceivedRelease = () => (
  <Layout background>
    <div className={classes.container}>
      <img src={confirmation} className={classes.image} alt="confirmation" />
      <Title size="xs" kind="h3" className={classes.title}>
        We Received Your Release
      </Title>
      <Paragraph size="s" className={classes.message}>
        A human being will listen to your release, and we will notify you.
      </Paragraph>
    </div>
  </Layout>
);

export default ReceivedRelease;
