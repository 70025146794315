import { QueryClient, QueryClientProvider } from "react-query";
import { IntercomProvider } from "react-use-intercom";

import Router from "./pages/Router";
import { AuthProvider } from "./providers/authContext";

import "./App.css";

const queryClient = new QueryClient();

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <IntercomProvider
          appId={
            process.env.REACT_APP_INTERCOM_APP_ID
              ? process.env.REACT_APP_INTERCOM_APP_ID
              : ""
          }
        >
          <AuthProvider>
            <Router />
          </AuthProvider>
        </IntercomProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
