import SignUpForm from "../../components/Forms/SignUpForm";
import Layout from "../../components/Navigation/Layout";
import Link from "../../components/Navigation/Link";
import Path from "../../enums/Path";
import SignUpFormProvider from "../../providers/signUpFormContext";

import classes from "./SignUp.module.scss";

const SignUp = () => (
  <Layout background className={classes.layout}>
    <div className={classes.container}>
      <SignUpFormProvider>
        <SignUpForm />
      </SignUpFormProvider>

      <div className={classes.links}>
        <Link to={Path.SignIn}>Already have an account?</Link>
      </div>
    </div>
  </Layout>
);

export default SignUp;
