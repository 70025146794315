import { useCallback, useMemo, useState, useContext } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FirebaseError } from "firebase/app";

import Form from "../../Common/Form";
import {
  signUpUserFields,
  validationSchema as validationUserSchema,
} from "../../../utils/formFields/signUpUserFields";
import FormControl from "../../Common/FormControl";
import Button from "../../Common/Button";
import Title from "../../Common/Title";
import Paragraph from "../../Common/Paragraph";
import {
  SignUpData,
  SignUpFormContext,
} from "../../../providers/signUpFormContext";
import { checkIfEmailAlreadyExists } from "../../../services/AuthService";
import Toast from "../../Common/Toast";
import beautifyError from "../../../utils/beautifyError";

import classes from "./SignUpUserForm.module.scss";

type SignUpUserSubmitProps = {
  goForward: () => void;
};

const SignUpUserForm = ({ goForward }: SignUpUserSubmitProps) => {
  const [error, setError] = useState("");
  const { signUpData, setSignUpData } = useContext(SignUpFormContext);

  const useUserFormProps = useMemo(
    () => ({
      resolver: yupResolver(validationUserSchema()),
      mode: "submit",
    }),
    []
  );

  const onSubmitHandler = useCallback(
    async (data: SignUpData) => {
      try {
        if (setSignUpData && !(await checkIfEmailAlreadyExists(data.email))) {
          setSignUpData({ ...signUpData, ...data });
          goForward();
        } else {
          setError("Email already exists! Please try another one.");
        }
      } catch (submitError) {
        setError(beautifyError(submitError as FirebaseError));
      }
    },
    [goForward, setSignUpData, signUpData]
  );

  const cleanFormErrorState = useCallback(() => {
    setError("");
  }, []);

  return (
    <>
      <Title kind="h3" size="xs">
        Create Your Account
      </Title>
      <Paragraph size="s" className={classes.welcome}>
        Promote your creation while making money.
      </Paragraph>
      <Form
        useFormProps={useUserFormProps}
        onSubmit={onSubmitHandler}
        className={classes.form}
        onFocus={cleanFormErrorState}
      >
        {signUpUserFields.map((field) => (
          <FormControl
            key={field.name}
            {...field}
            inputClassName={classes.input}
          />
        ))}
        <div className={classes.buttonContainer}>
          <Button type="submit" className={classes.button}>
            NEXT
          </Button>
        </div>
        {error && <Toast text={error} id="sign-up-email-duplicated" />}
      </Form>
    </>
  );
};

export default SignUpUserForm;
