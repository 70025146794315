import classNames from "classnames";

import classes from "./Paragraph.module.scss";

type Size = "xl" | "l" | "m" | "s" | "xs" | "xxs";

export type ParagraphProps = {
  children: React.ReactNode;
  className?: string;
  size?: Size;
  key?: string;
};

const Paragraph = ({
  children,
  className = "",
  size = "l",
  key = "1",
}: ParagraphProps) => (
  <p
    key={key}
    className={classNames(classes.paragraph, classes[size], className)}
  >
    {children}
  </p>
);

export default Paragraph;
