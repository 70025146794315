import { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useFormContext, Controller } from "react-hook-form";
import classNames from "classnames";

import ErrorMessage from "../ErrorMessage";
import Label from "../Label";
import plus from "../../../assets/images/plus.png";

import classes from "./ImageFormControl.module.scss";

const fileTypes = ["JPEG", "JPG", "PNG"];

type ImageFormControlProps = {
  name: string;
};

const ImageFormControl = ({ name }: ImageFormControlProps) => {
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState("");

  const methods = useFormContext();
  const {
    control,
    formState: { errors },
  } = methods;

  const error = errors && errors[name];

  const handleImageChange = (event: any) => {
    if (preview) {
      URL.revokeObjectURL(preview);
    }

    const previewUrl = URL.createObjectURL(event);
    setPreview(previewUrl);
    setImage(event);
  };

  useEffect(
    () => () => {
      URL.revokeObjectURL(preview);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className={classes.container}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, ref } }) => (
          <FileUploader
            handleChange={(value: any) => {
              onChange(value);
              handleImageChange(value);
            }}
            types={fileTypes}
            ref={ref}
            className={classes.container}
          >
            <div className={classes.uploadImage}>
              {image ? (
                <img
                  src={preview}
                  className={classNames(classes.imagePreview, classes.pointer)}
                  alt="preview"
                />
              ) : (
                <div className={classes.pointer}>
                  <img src={plus} className={classes.icon} alt="plus" />
                  <Label className={classes.pointer}> UPLOAD ARTWORK </Label>
                </div>
              )}
            </div>
            {error?.message && (
              <ErrorMessage>{error?.message?.toString()}</ErrorMessage>
            )}
          </FileUploader>
        )}
      />
    </div>
  );
};

export default ImageFormControl;
