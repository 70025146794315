import { toast } from "react-toastify";

export type Type = "error" | "success" | "info";

type ToastProps = {
  text: string;
  id: string;
  type?: Type;
};

const Toast = ({ text, id, type = "error" }: ToastProps) => {
  toast[type](text, {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    toastId: id,
  });
  return null;
};

export default Toast;
