import { useState, useEffect } from "react";

import Spinner from "components/Common/Spinner";
import Title from "components/Common/Title";
import LayoutDivider from "components/Common/LayoutDivider";
import Tabs from "components/Common/Tabs";
import TracksComponent from "components/Tracks/TracksComponent";
import Toast from "components/Common/Toast";
import getTracks from "services/getTracks";
import Timelines from "enums/Timelines";
import Track from "types/Track";

import classes from "./Home.module.scss";

const Home = () => {
  const [activeTab, setActiveTab] = useState(3);
  const [tracks, setTracks] = useState<Array<Track>>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const handleGetTracks = async () => {
    try {
      const updatedTracks: Track[] = await getTracks();
      setTracks(updatedTracks);
      setLoading(false);
    } catch (getTracksError) {
      const { message } = getTracksError as Error;
      setLoading(false);
      setError(message);
    }
  };

  useEffect(() => {
    setLoading(true);
    handleGetTracks();
  }, []);

  return (
    <div className={classes.layout}>
      <Title className={classes.layoutTitle} kind="h3" size="xs">
        Your Releases
      </Title>
      <Tabs
        tabs={Timelines}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <LayoutDivider />
      {loading ? (
        <Spinner color="black" size={60} />
      ) : (
        <TracksComponent tracks={tracks} />
      )}
      {error && <Toast text={error} id="new-release-error-creation" />}
    </div>
  );
};

export default Home;
