import Layout from "components/Navigation/Layout";
import Paragraph from "components/Common/Paragraph";
import Title from "components/Common/Title";
import confirmation from "assets/images/confirmation.png";

import classes from "./SentForgotPassword.module.scss";

const SentForgotPassword = () => (
  <Layout background>
    <div className={classes.container}>
      <img src={confirmation} className={classes.image} alt="confirmation" />
      <Title size="xs" kind="h3" className={classes.title}>
        Password Recovery Sent
      </Title>
      <Paragraph className={classes.text} size="s">
        Check your email to reset your password
      </Paragraph>
    </div>
  </Layout>
);

export default SentForgotPassword;
