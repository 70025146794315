/* eslint-disable @typescript-eslint/no-empty-function */
import classNames from "classnames";

import classes from "./Input.module.scss";

export type InputProps = {
  className?: string;
  disabled?: boolean;
  register?: any;
  onChange?: (value: string) => void;
  name?: string;
  validation?: any;
  defaultValue?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const Input = ({
  className = "",
  disabled = false,
  register,
  onChange = () => {},
  type = "text",
  placeholder = "",
  name = "",
  validation = {},
  defaultValue = "",
  ...props
}: InputProps) => (
  <input
    type={type}
    name={name}
    placeholder={placeholder}
    className={classNames(classes.input, className, {
      [classes.disabled]: disabled,
    })}
    defaultValue={defaultValue}
    onChange={(e) => onChange(e.target.value)}
    {...register?.(name, validation)}
    {...props}
  />
);

export default Input;
