import { BrowserRouter, Routes, Route } from "react-router-dom";

import ConfirmDeactivateAccount from "pages/ConfirmDeactivateAccount/ConfirmDeactivateAccount";
import ForgotPassword from "pages/ForgotPassword";
import SignIn from "pages/SignIn";
import SignUp from "pages/SignUp";
import Home from "pages/Home";
import ThanksForApplying from "pages/ThanksForApplying";
import SentForgotPassword from "pages/SentForgotPassword";
import NewRelease from "pages/NewRelease";
import ReceivedRelease from "pages/ReceivedRelease";
import Wallet from "pages/Wallet/Wallet";
import Account from "pages/Account";
import PrivateRoute from "components/Navigation/PrivateRoute";
import Path from "enums/Path";

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<SignIn />} path={Path.SignIn} />
      <Route element={<SignUp />} path={Path.SignUp} />
      <Route element={<ThanksForApplying />} path={Path.ThanksForApplying} />
      <Route element={<ReceivedRelease />} path={Path.ReceivedRelease} />
      <Route
        element={
          <PrivateRoute burger upload>
            <Home />
          </PrivateRoute>
        }
        path={Path.Home}
      />
      <Route
        element={
          <PrivateRoute burger>
            <NewRelease />
          </PrivateRoute>
        }
        path={Path.NewRelease}
      />
      <Route
        element={
          <PrivateRoute burger upload>
            <Wallet />
          </PrivateRoute>
        }
        path={Path.Wallet}
      />
      <Route
        element={
          <PrivateRoute burger upload>
            <Account />
          </PrivateRoute>
        }
        path={Path.Account}
      />
      <Route
        element={
          <PrivateRoute burger upload>
            <ConfirmDeactivateAccount />
          </PrivateRoute>
        }
        path={Path.ConfirmDeactivateAccount}
      />
      <Route element={<ForgotPassword />} path={Path.ForgotPassword} />
      <Route element={<SentForgotPassword />} path={Path.SentForgotPassword} />
    </Routes>
  </BrowserRouter>
);

export default Router;
