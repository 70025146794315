enum Path {
  SignIn = "/",
  SignUp = "/sign-up",
  ThanksForApplying = "/thanks-for-applying",
  Home = "/home",
  ForgotPassword = "/forgot-password",
  SentForgotPassword = "/sent-forgot-password",
  NewRelease = "/new-release",
  ReceivedRelease = "/received-release",
  Wallet = "/wallet",
  Account = "/account",
  ConfirmDeactivateAccount = "/confirm-deactivate-account",
}

export default Path;
