import classNames from "classnames";

import classes from "./Label.module.scss";

export type LabelProps = {
  className?: string;
  containerClassName?: string;
  children?: React.ReactNode;
};

const Label = ({ className, containerClassName, children }: LabelProps) => (
  <div className={containerClassName}>
    <label className={classNames(classes.label, className)}>{children}</label>
  </div>
);

export default Label;
