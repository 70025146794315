import React, { useEffect, useState } from "react";

import LayoutDivider from "components/Common/LayoutDivider";
import WalletData from "components/Wallet/WalletData";
import Toast from "components/Common/Toast";
import WalletTracks from "components/Wallet/WalletTracks";
import getTracks from "services/getTracks";
import Track from "types/Track";

import classes from "./Wallet.module.scss";

const Wallet = () => {
  const [tracks, setTracks] = useState<Track[]>([]);
  const [totalTokens, setTotalTokens] = useState(0);
  const [error, setError] = useState("");

  const handleGetTracksTokens = async () => {
    try {
      const updatedTracks: Track[] = await getTracks();

      setTracks(updatedTracks);

      const sum = updatedTracks.reduce(
        (accumulator, track) => accumulator + +track.doseTokens,
        0
      );

      setTotalTokens(sum);
    } catch (getTracksError) {
      const { message } = getTracksError as Error;
      setError(message);
    }
  };

  useEffect(() => {
    handleGetTracksTokens();
  }, []);

  return (
    <div className={classes.topContainer}>
      <WalletData doseTokens={totalTokens} />
      <LayoutDivider />
      <WalletTracks tracks={tracks} />
      {error && <Toast text={error} id="new-release-error-creation" />}
    </div>
  );
};

export default Wallet;
