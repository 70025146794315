import { useState } from "react";

const useMultipleStepFormProgress = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const goForward = () => {
    setCurrentStep((prevState) => prevState + 1);
  };

  const goBack = () => {
    setCurrentStep((prevState) => prevState - 1);
  };

  return [currentStep, goForward, goBack];
};

export default useMultipleStepFormProgress;
