import EmptyReleaseBox from "components/Tracks/EmptyReleaseBox";
import ReleaseBox from "components/Tracks/ReleaseBox";
import Track from "types/Track";

import classes from "./TracksComponent.module.scss";

type TracksComponentProps = {
  tracks: Array<Track>;
};

const TracksComponent = ({ tracks }: TracksComponentProps) => (
  <div className={classes.tracksComponentBox}>
    <EmptyReleaseBox />
    {tracks.map(({ title, timesPlayed, artworkUrl }) => (
      <ReleaseBox
        title={title}
        listeners={timesPlayed}
        image={artworkUrl}
        key={title}
      />
    ))}
  </div>
);

export default TracksComponent;
