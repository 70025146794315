import { useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";

import { FirebaseError } from "firebase/app";

import Paragraph from "components/Common/Paragraph";
import Button from "components/Common/Button";
import Layout from "components/Navigation/Layout";
import Title from "components/Common/Title";
import Form from "components/Common/Form";
import Link from "components/Navigation/Link";
import FormControl from "components/Common/FormControl";
import Toast from "components/Common/Toast";
import beautifyError from "utils/beautifyError";
import Path from "enums/Path";
import {
  forgotPasswordFields,
  validationSchema,
} from "utils/formFields/forgotPasswordFields";
import { forgotPassword } from "services/AuthService";

import classes from "./ForgotPassword.module.scss";

type ForgotPassowrdSubmitProps = {
  email: string;
};

const ForgotPassword = () => {
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const useFormProps = useMemo(
    () => ({
      resolver: yupResolver(validationSchema()),
      mode: "all",
    }),
    []
  );

  const onSubmitHandler = async ({ email }: ForgotPassowrdSubmitProps) => {
    try {
      await forgotPassword(email);
      navigate(Path.SentForgotPassword);
    } catch (submitError) {
      setError(beautifyError(submitError as FirebaseError));
    }
  };

  return (
    <Layout background>
      {error && <Toast text={error} id="forgot-password-error" />}
      <div className={classes.container}>
        <Title kind="h3" size="xs" className={classes.title}>
          Password Recovery
        </Title>
        <Paragraph size="s">
          We will send you instructions on how to recover your password to your
          email.
        </Paragraph>
        <Form
          onSubmit={onSubmitHandler}
          useFormProps={useFormProps}
          className={classes.form}
        >
          {forgotPasswordFields.map((field) => (
            <FormControl key={field.name} {...field} />
          ))}
          <div className={classes.buttonContainer}>
            <Button type="submit" className={classes.button}>
              RECOVER
            </Button>
          </div>
        </Form>
        <div className={classes.links}>
          <Link to={Path.SignUp} className={classes.link}>
            Don’t Have An Account?
          </Link>
          <Link to={Path.SignIn} className={classes.link}>
            Back to Sign In
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default ForgotPassword;
