import Select from "react-select";
import { useFormContext, Controller } from "react-hook-form";
import classNames from "classnames";

import ErrorMessage from "components/Common/ErrorMessage";
import Label from "components/Common/Label";
import customStyles from "utils/global/dropdownStyles";

import classes from "./SelectFormControl.module.scss";

type SelectFormControlProps = {
  name: string;
  placeholder?: string;
  options?: { value: string; label: string }[];
  label?: string;
  labelClassName?: string;
};

const SelectFormControl = ({
  name,
  placeholder,
  options,
  label,
  labelClassName,
}: SelectFormControlProps) => {
  const methods = useFormContext();
  const {
    control,
    formState: { errors },
  } = methods;

  const error = errors && errors[name];

  return (
    <div
      className={classNames(classes.container, {
        [classes.error]: error,
      })}
    >
      {label && (
        <Label className={classNames(labelClassName, classes.label)}>
          {label}
        </Label>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value, ref } }) => (
          <Select
            placeholder={placeholder}
            styles={customStyles}
            className={classes.select}
            ref={ref}
            onChange={(val: any) => onChange(val.value)}
            value={options?.filter((c: any) => value === c.value)}
            options={options}
          />
        )}
      />
      {error?.message && (
        <ErrorMessage>{error?.message?.toString()}</ErrorMessage>
      )}
    </div>
  );
};

export default SelectFormControl;
