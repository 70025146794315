import Title from "../../components/Common/Title";
import NewReleaseForm from "../../components/Forms/NewReleaseForm";

import classes from "./NewRelease.module.scss";

const NewRelease = () => (
  <div>
    <Title size="xs" kind="h3" className={classes.title}>
      Add A New Release
    </Title>
    <NewReleaseForm />
  </div>
);

export default NewRelease;
