/* eslint-disable jsx-a11y/anchor-has-content */
import { ReactNode } from "react";

import Button, { Kind } from "components/Common/Button";
import { logOut } from "services/AuthService";

type LinkComponentProps = {
  href?: string;
  kind?: Kind;
  children: ReactNode;
  className?: string;
};

const Anchor = (props: LinkComponentProps) => <a {...props} />;

export const links: {
  title: string;
  href?: string;
  target?: string;
  kind?: Kind;
  onClick?: () => void;
  Component: (props: LinkComponentProps) => JSX.Element;
}[] = [
  {
    title: "FAQ",
    href: "http://help.dose.fm/en/",
    Component: Anchor,
  },
  {
    title: "Talk To Us",
    kind: "minimal",
    Component: Button,
  },
  {
    title: "Terms Of Service",
    href: "https://www.dose.fm/creators-terms-and-conditions/",
    Component: Anchor,
  },
  {
    title: "Privacy",
    href: "https://www.dose.fm/creators-privacy/",
    Component: Anchor,
  },
  {
    title: "Logout",
    kind: "minimal",
    onClick: logOut,
    Component: Button,
  },
  {
    title: "Delete Account",
    kind: "minimal",
    Component: Button,
  },
];
