import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { FirebaseError } from "firebase/app";

import Paragraph from "components/Common/Paragraph";
import Form from "components/Common/Form";
import Button from "components/Common/Button";
import Toast from "components/Common/Toast";
import {
  newReleaseFields,
  validationSchema,
} from "utils/formFields/newReleaseFields";
import { uploadNewRelease } from "services/SongService";
import { AuthContext } from "providers/authContext";
import Path from "enums/Path";
import beautifyError from "utils/beautifyError";

import classes from "./NewReleaseForm.module.scss";

type NewReleaseFormProps = {
  audio: File;
  image: File;
  explicitContent: boolean;
  name: string;
};

type UploadImageProgress = {
  bytesTransferredTrack: number;
  totalBytesTrack: number;
};

const NewReleaseForm = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState<UploadImageProgress>({
    bytesTransferredTrack: 0,
    totalBytesTrack: -1,
  });

  const { user } = useContext(AuthContext);

  const useArtistFormProps = useMemo(
    () => ({
      resolver: yupResolver(validationSchema()),
      mode: "all",
    }),
    []
  );

  const onSubmitHandler = async (data: NewReleaseFormProps) => {
    try {
      setIsLoading(true);
      await uploadNewRelease(data, user?.uid, setProgress);
    } catch (submitError) {
      setError(beautifyError(submitError as FirebaseError));
    }
  };

  const { bytesTransferredTrack, totalBytesTrack } = progress;

  useEffect(() => {
    if (bytesTransferredTrack === totalBytesTrack) {
      navigate(Path.ReceivedRelease);
      setIsLoading(false);
    }
  }, [bytesTransferredTrack, navigate, totalBytesTrack]);

  return (
    <Form
      useFormProps={useArtistFormProps}
      onSubmit={onSubmitHandler}
      className={classes.form}
    >
      {newReleaseFields.map(({ formControl: FormControl, ...field }) => (
        <FormControl key={field.name} progress={progress} {...field} />
      ))}
      <div className={classes.buttonContainer}>
        <Button
          loading={isLoading}
          size="L"
          type="submit"
          className={classes.button}
        >
          <Paragraph size="m">SAVE</Paragraph>
        </Button>
      </div>
      {error && <Toast text={error} id="new-release-error-creation" />}
    </Form>
  );
};

export default NewReleaseForm;
