import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  sendPasswordResetEmail,
  setPersistence,
  browserSessionPersistence,
  signOut,
  deleteUser,
} from "firebase/auth";
import { doc, getDoc, Timestamp, updateDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { SetStateAction } from "react";

import UserCredentials from "types/UserCredentials";
import { app, db } from "../firebase";
import { SignUpData } from "../providers/signUpFormContext";
import getTracks from "./getTracks";

const getUser = async (uid: string) => {
  try {
    const artistRef = doc(db, "artists", uid);
    const artistData = await getDoc(artistRef);
    const userData = artistData.data();

    if (userData) {
      const {
        email,
        firstName,
        lastName,
        phoneNumber,
        artistName,
        country,
        city,
        spotify,
        appleMusic,
        website,
      } = userData;
      const userValues: SetStateAction<UserCredentials> = {
        email,
        firstName,
        lastName,
        phoneNumber,
        artistName,
        country,
        city,
        spotify,
        appleMusic,
        website,
        password: "",
        currentPassword: "",
        confirmPassword: "",
      };
      return userValues;
    }
    throw new Error("You are not logged in");
  } catch (err: unknown) {
    const error = err as Error;
    throw new Error(error.message);
  }
};

const signIn = async (email: string, password: string) => {
  const auth = getAuth(app);
  setPersistence(auth, browserSessionPersistence);

  const { user } = await signInWithEmailAndPassword(auth, email, password);
  const token = await user.getIdToken();
  if (!token) throw new Error("Failed to login!");
  localStorage.setItem("token", token);
  return user;
};

const logOut = async () => {
  const auth = getAuth();
  await signOut(auth);
};

const signUp = async (userData?: SignUpData) => {
  if (!userData) throw new Error("No user data provided!");
  const {
    email,
    password,
    firstName,
    lastName,
    phoneNumber,
    artistName,
    country,
    city,
    spotify,
    appleMusic,
    website,
  } = userData;
  const auth = getAuth(app);

  const { user } = await createUserWithEmailAndPassword(auth, email, password);

  const { uid: authUserId } = user;

  const functions = getFunctions();
  const createFirestoreArtist = httpsCallable(functions, "createArtist");

  await createFirestoreArtist({
    id: authUserId,
    email,
    firstName,
    lastName,
    phoneNumber,
    artistName,
    country,
    city,
    spotify,
    appleMusic,
    website,
  });
};

const checkIfEmailAlreadyExists = async (email: string) => {
  const auth = getAuth(app);

  const authResponse = await fetchSignInMethodsForEmail(auth, email);

  return authResponse.length > 0;
};

const forgotPassword = async (email: string) => {
  const auth = getAuth(app);
  const userExists = (await fetchSignInMethodsForEmail(auth, email)).length > 0;

  if (userExists) {
    await sendPasswordResetEmail(auth, email);
  }
};

const deactivateAccount = async () => {
  const auth = getAuth();

  if (auth.currentUser) {
    const user = auth.currentUser;
    const { uid } = user;
    const artistRef = doc(db, "artists", uid);
    const deactivationDate = Timestamp.now();
    await updateDoc(artistRef, { deactivated: deactivationDate });

    const tracks = await getTracks();
    const deleteTracksArray = tracks.map(({ id, title }) => ({ id, title }));

    const functions = getFunctions();
    const deleteTracksOfUser = httpsCallable(functions, "deleteTracksFunction");
    if (deleteTracksArray.length > 0) {
      deleteTracksOfUser(deleteTracksArray);
    }
    await deleteUser(user);
  }
};

export {
  signIn,
  signUp,
  checkIfEmailAlreadyExists,
  forgotPassword,
  logOut,
  getUser,
  deactivateAccount,
};
