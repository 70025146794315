import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useFormContext, Controller } from "react-hook-form";
import classNames from "classnames";

import audioFormats from "utils/formats/audioFormat";
import ErrorMessage from "../ErrorMessage";
import Label from "../Label";

import classes from "./AudioFormControl.module.scss";

const fileTypes = audioFormats;

type UploadImageProgress = {
  bytesTransferredTrack: number;
  totalBytesTrack: number;
};

type AudioFormControlProps = {
  name: string;
  progress: UploadImageProgress;
};

const AudioFormControl = ({ name, progress }: AudioFormControlProps) => {
  const [audio, setAudio] = useState<File>();

  const methods = useFormContext();
  const {
    control,
    formState: { errors },
  } = methods;

  const error = errors && errors[name];

  const handleAudioChange = (event: any) => {
    setAudio(event);
  };

  return (
    <div className={classes.container}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, ref } }) => (
          <FileUploader
            handleChange={(value: any) => {
              onChange(value);
              handleAudioChange(value);
            }}
            types={fileTypes}
            ref={ref}
            className={classes.container}
          >
            <div
              className={classNames(classes.uploadAudio, {
                [classes.border]: !audio,
              })}
            >
              {audio ? (
                <div className={classes.uploadedAudio}>
                  <Label containerClassName={classes.labelContainer}>
                    {audio.name}
                  </Label>
                  <div
                    className={classes.progressBar}
                    style={{
                      width: `${
                        (progress.bytesTransferredTrack /
                          progress.totalBytesTrack) *
                        100
                      }%`,
                    }}
                  />
                </div>
              ) : (
                <Label className={classes.label}>UPLOAD TRACK</Label>
              )}
            </div>
            {error?.message && (
              <ErrorMessage>{error?.message?.toString()}</ErrorMessage>
            )}
          </FileUploader>
        )}
      />
    </div>
  );
};

export default AudioFormControl;
