import classes from "./LayoutDivider.module.scss";

const LayoutDivider = () => (
  <div>
    <div className={classes.firstDivider} />
    <div className={classes.secondDivider} />
    <div className={classes.thirdDivider} />
  </div>
);

export default LayoutDivider;
