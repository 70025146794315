import Paragraph from "components/Common/Paragraph";
import Link from "components/Navigation/Link";
import plus from "assets/images/plus.png";
import Path from "enums/Path";

import classes from "./EmptyReleaseBox.module.scss";

const EmptyReleaseBox = () => (
  <div className={classes.newReleaseContainer}>
    <Link to={Path.NewRelease}>
      <div className={classes.newReleaseBox}>
        <img
          className={classes.newReleaseImage}
          src={plus}
          alt="Add new release button"
        />
        <Paragraph className={classes.newReleaseText} size="s">
          ADD NEW RELEASE
        </Paragraph>
      </div>
    </Link>
  </div>
);

export default EmptyReleaseBox;
