import { deactivateAccount } from "services/AuthService";
import Button from "components/Common/Button";
import Title from "components/Common/Title";
import Path from "enums/Path";
import trashcan from "assets/images/trashcan.png";

import classes from "./ConfirmDeactivateAccount.module.scss";

const ConfirmDeactivateAccount = () => (
  <div className={classes.container}>
    <img src={trashcan} alt="Trash icon" />
    <Title size="xs" className={classes.title}>
      Delete Account
    </Title>
    <p className={classes.description}>
      Are you sure you want to delete your account?
    </p>
    <div className={classes.buttons}>
      <Button
        kind="cancellation"
        buttonLink={Path.Account}
        className={classes.button}
        linkText="NO"
      />
      <Button className={classes.button} onClick={deactivateAccount}>
        YES
      </Button>
    </div>
  </div>
);

export default ConfirmDeactivateAccount;
