import * as yup from "yup";

export const forgotPasswordFields = [
  {
    name: "email",
    type: "email",
    placeholder: "EMAIL",
  },
];

export const validationSchema = () =>
  yup.object().shape({
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),
  });
