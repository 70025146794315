import { Link as RouterLink } from "react-router-dom";
import classNames from "classnames";

import classes from "./Link.module.scss";

export type LinkProps = {
  to: string;
  children: React.ReactNode;
  underlined?: boolean;
  className?: string;
};

const Link = ({ to, children, underlined, className }: LinkProps) => (
  <RouterLink
    to={to}
    className={classNames(classes.link, className, {
      [classes.underlined]: underlined,
    })}
  >
    {children}
  </RouterLink>
);

export default Link;
