import { useCallback, useMemo, useState, useContext } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FirebaseError } from "firebase/app";

import Form from "../../Common/Form";
import {
  signUpArtistFields,
  validationSchema as validationArtistSchema,
} from "../../../utils/formFields/signUpArtistFields";
import Button from "../../Common/Button";
import Title from "../../Common/Title";
import Paragraph from "../../Common/Paragraph";
import ErrorMessage from "../../Common/ErrorMessage";
import {
  SignUpData,
  SignUpFormContext,
} from "../../../providers/signUpFormContext";
import beautifyError from "../../../utils/beautifyError";

import classes from "./SignUpArtistForm.module.scss";

const SignUpArtistForm = () => {
  const [error, setError] = useState("");
  const { signUpData, setSignUpData } = useContext(SignUpFormContext);

  const useArtistFormProps = useMemo(
    () => ({
      resolver: yupResolver(validationArtistSchema()),
      mode: "all",
    }),
    []
  );

  const onSubmitHandler = useCallback(
    async (data: SignUpData) => {
      try {
        setSignUpData({ ...signUpData, ...data });
      } catch (submitError) {
        setError(beautifyError(submitError as FirebaseError));
      }
    },
    [setSignUpData, signUpData]
  );

  return (
    <>
      <Title kind="h3" size="xs">
        Artist Profile
      </Title>
      <Paragraph size="s" className={classes.welcome}>
        We want to know you more as an artist.
      </Paragraph>
      <Form
        useFormProps={useArtistFormProps}
        onSubmit={onSubmitHandler}
        className={classes.form}
      >
        {signUpArtistFields.map(({ formControl: FormControl, ...field }) => (
          <FormControl
            key={field.name}
            {...field}
            inputClassName={classes.input}
            className={classes.inputContainer}
          />
        ))}
        <div className={classes.buttonContainer}>
          <Button type="submit" className={classes.button}>
            CONFIRM
          </Button>
        </div>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Form>
    </>
  );
};

export default SignUpArtistForm;
