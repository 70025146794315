import { useState } from "react";
import classNames from "classnames";

import Button from "components/Common/Button";
import BurguerMenuNav from "components/Navigation/BurgerMenu/BurguerMenuNav";
import BurgerMenu from "components/Navigation/BurgerMenu";
import doseForCreators from "assets/images/dose-for-creators.png";
import Path from "enums/Path";
import plus from "assets/images/plus.png";
import whitePlus from "assets/images/plus-white.png";
import doseForCreatorsWhite from "assets/images/dose-for-creators-white.png";

import classes from "./Header.module.scss";

export type HeaderProps = {
  burger?: boolean;
  upload?: boolean;
  background?: boolean;
  headerLogoPath: string;
};

const Header = ({
  burger = false,
  upload = false,
  background,
  headerLogoPath,
}: HeaderProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <header
        className={classNames(classes.principal, {
          [classes.background]: background,
          [classes.navOpened]: open,
        })}
      >
        {open && (
          <Button
            kind="minimal"
            className={classes.closeContainer}
            onClick={() => setOpen(!open)}
          >
            <span className={classes.close} />
          </Button>
        )}
        {burger && !open && (
          <BurgerMenu className={classes.links} onClick={() => setOpen(true)} />
        )}
        <nav
          className={classNames(classes.nav, {
            [classes.marginLeftBurger]: !burger,
            [classes.marginRightAdd]: !upload,
          })}
        >
          <Button
            kind="minimal"
            icon={open ? doseForCreatorsWhite : doseForCreators}
            iconAlt="Dose logo"
            iconClass={classes.logo}
            iconLink={headerLogoPath}
            onClick={() => setOpen(false)}
          />
        </nav>
        {upload && (
          <Button
            className={classes.addContainer}
            kind="minimal"
            onClick={() => setOpen(false)}
            icon={open ? whitePlus : plus}
            iconClass={classes.addIcon}
            iconAlt="plus"
            iconLink={Path.NewRelease}
          />
        )}
      </header>

      {open && <BurguerMenuNav onClick={() => setOpen(!open)} />}
    </>
  );
};

export default Header;
