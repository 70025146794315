import React, { SetStateAction } from "react";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import { uuidv4 } from "@firebase/util";
import { getFunctions, httpsCallable } from "firebase/functions";

import { app } from "../firebase";
import getAudioDuration from "../utils/global/getAudioDuration";
import { authErrors } from "../common/errors";

type DataType = {
  image: File;
  audio: File;
  name: string;
  explicitContent: boolean;
};

type UploadImageProgress = {
  bytesTransferredTrack: number;
  totalBytesTrack: number;
};

type UploadTrack = {
  id: string;
  title: string;
  artworkUrl?: string;
  audioUrl?: string;
  explicitContent: boolean;
  artistId: string;
  durationInSeconds: unknown;
};

const uploadNewRelease = async (
  data: DataType,
  artistId: string | undefined,
  setProgress: React.Dispatch<SetStateAction<UploadImageProgress>>
) => {
  if (!artistId) {
    throw new Error(authErrors.noArtistId);
  }

  const functions = getFunctions();
  const createFirestoreNewRelease = httpsCallable(
    functions,
    "createNewRelease"
  );

  const { image, name, audio, explicitContent } = data;

  const storage = getStorage(app);

  const trackId = uuidv4();

  const durationInSeconds = await getAudioDuration(audio);

  const imageRef = ref(storage, `tracks/${trackId}/image`);
  const audioRef = ref(storage, `tracks/${trackId}/${name}`);

  const uploadTrack = uploadBytesResumable(audioRef, audio);

  await uploadBytes(imageRef, image);

  const artworkUrl = await getDownloadURL(imageRef);

  const track: UploadTrack = {
    id: trackId,
    title: name,
    explicitContent,
    artistId,
    artworkUrl,
    durationInSeconds,
  };

  uploadTrack.on(
    "state_changed",
    (snapshotTrack) => {
      const {
        bytesTransferred: bytesTransferredTrack,
        totalBytes: totalBytesTrack,
      } = snapshotTrack;

      setProgress((prevState) => ({
        ...prevState,
        bytesTransferredTrack,
        totalBytesTrack,
      }));
    },
    (error) => {
      deleteObject(imageRef);
      throw error;
    },
    () => {
      getDownloadURL(uploadTrack.snapshot.ref).then(async (downloadURL) => {
        createFirestoreNewRelease({
          ...track,
          audioUrl: downloadURL,
        });
      });
    }
  );
};

export { uploadNewRelease };
