import * as yup from "yup";
import { countriesList } from "../global/countriesList";
import SelectFormControl from "../../components/Common/SelectFormControl";
import FormControl from "../../components/Common/FormControl";

export const signUpArtistFields = [
  {
    name: "artistName",
    type: "text",
    placeholder: "ARTIST NAME",
    formControl: FormControl,
  },
  {
    name: "country",
    type: "text",
    placeholder: "SELECT COUNTRY",
    options: countriesList,
    formControl: SelectFormControl,
  },
  {
    name: "city",
    type: "text",
    placeholder: "CITY",
    formControl: FormControl,
  },
  {
    name: "spotify",
    type: "text",
    placeholder: "SPOTIFY",
    formControl: FormControl,
  },
  {
    name: "appleMusic",
    type: "text",
    placeholder: "APPLE MUSIC",
    formControl: FormControl,
  },
  {
    name: "website",
    type: "text",
    placeholder: "SOCIAL MEDIA (INSTAGRAM, TIKTOK...)",
    formControl: FormControl,
  },
];

export const validationSchema = () =>
  yup.object().shape({
    artistName: yup.string().required("Artist Name is required"),
    country: yup.string().required("Country is required"),
    city: yup.string().required("City is required"),
    spotify: yup
      .string()
      .test(
        "oneOfRequired",
        "Please enter at least one audio service url",
        (value, context) =>
          (context.parent.appleMusic.match(
            /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
          ) &&
            context.parent.appleMusic.includes("apple.com")) ||
          (value?.includes("spotify.com") &&
            value?.match(
              /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
            ))
      ),
    appleMusic: yup
      .string()
      .test(
        "oneOfRequired",
        "Please enter at least one audio service url",
        (value, context) =>
          (context.parent.spotify.match(
            /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
          ) &&
            context.parent.spotify.includes("spotify.com")) ||
          (value?.match(
            /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
          ) &&
            value.includes("apple.com"))
      ),
    website: yup
      .string()
      .test(
        "socialMedia",
        "Please enter your social media url",
        (value) =>
          !!value?.match(
            /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
          )
      ),
  });
