import classNames from "classnames";

import Button from "components/Common/Button";

import classes from "./BurgerMenu.module.scss";

type BurgerMenuProps = {
  className?: string;
  onClick: () => void;
};

const BurgerMenu = ({ className, onClick }: BurgerMenuProps) => {
  const lines = [
    {
      width: "16",
      viewBox: "0 0 16 1",
      id: "vrqa0rlgia",
      transformValue: "-52 -43",
      d: "M52.5 43.5h15",
    },
    {
      width: "24",
      viewBox: "0 0 24 1",
      id: "pcr2pw1dma",
      transformValue: "-52 -54",
      d: "M52.5 54.5h23",
    },
    {
      width: "30",
      viewBox: "0 0 30 1",
      id: "afko5pohja",
      transformValue: "-52 -65",
      d: "M52.5 65.5h29",
    },
  ];

  return (
    <Button
      kind="minimal"
      className={classNames(classes.burger, className)}
      onClick={onClick}
    >
      {lines.map(({ width, viewBox, id, transformValue, d }) => (
        <svg
          className={classes.line}
          width={width}
          height="1"
          viewBox={viewBox}
          xmlns="http://www.w3.org/2000/svg"
          key={id}
        >
          <defs>
            <clipPath id={id}>
              <path d="M1440 0v1080H0V0h1440z" />
            </clipPath>
          </defs>
          <g
            clipPath={`url(#${id})`}
            transform={`translate(${transformValue})`}
          >
            <path
              d={d}
              stroke="#FF585A"
              fill="none"
              strokeLinecap="square"
              strokeMiterlimit="10"
            />
          </g>
        </svg>
      ))}
    </Button>
  );
};

export default BurgerMenu;
