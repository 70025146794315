import classNames from "classnames";

import Button from "components/Common/Button";

import classes from "./Tabs.module.scss";

const Tabs = ({
  tabs,
  activeTab,
  setActiveTab,
}: {
  tabs: { title: string }[];
  activeTab: number;
  setActiveTab: (index: number) => void;
}) => (
  <div className={classes.tabs}>
    {tabs.map(({ title, ...fields }, index) => (
      <Button
        kind="minimal"
        key={title}
        className={classNames(classes.tab, {
          [classes.active]: activeTab === index,
        })}
        onClick={() => setActiveTab(index)}
        {...fields}
      >
        {title}
      </Button>
    ))}
  </div>
);

export default Tabs;
