import Paragraph from "components/Common/Paragraph";
import AccountForm from "components/Forms/AccountEditForm";

import classes from "./Account.module.scss";

const Account = () => (
  <>
    <Paragraph size="m" className={classes.title}>
      Your Account
    </Paragraph>
    <AccountForm />
  </>
);

export default Account;
