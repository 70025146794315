const getAudioDuration = (audio: File) =>
  new Promise((resolve) => {
    const preview = URL.createObjectURL(audio);

    const myAudio = document.createElement("audio");
    myAudio.src = preview;

    myAudio.addEventListener("loadedmetadata", () => {
      resolve(Math.trunc(myAudio.duration));
    });
  });

export default getAudioDuration;
