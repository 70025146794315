import classNames from "classnames";
import { useFormContext } from "react-hook-form";

import Label from "components/Common/Label";
import Input from "components/Common/Input";
import ErrorMessage from "components/Common/ErrorMessage";

import classes from "./FormControl.module.scss";

export type FormControlProps = {
  type?: string;
  name: string;
  label?: string;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  labelClassName?: string;
  disabled?: boolean;
  caption?: string;
  defaultValue?: string;
  conditionToShow?: {
    validator: (value: string) => boolean;
    fieldToCheck: string;
  };
  [key: string]: any;
};

const FormControl = ({
  type = "text",
  name,
  label,
  placeholder,
  className,
  labelClassName,
  disabled,
  defaultValue,
  inputClassName,
  caption,
  conditionToShow,
  ...rest
}: FormControlProps) => {
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext();

  const error = errors && errors[name];

  const conditionIsFulfilled =
    !conditionToShow ||
    conditionToShow.validator(watch(conditionToShow.fieldToCheck));

  return conditionIsFulfilled ? (
    <div
      className={classNames(className, classes.container, {
        [classes.error]: error,
      })}
    >
      {label && (
        <Label
          className={classNames(labelClassName, classes.label, {
            [classes.error]: !!error,
          })}
        >
          {label}
        </Label>
      )}
      {caption && (
        <Label
          className={classNames(classes.caption, {
            [classes.error]: !!error,
          })}
        >
          {caption}
        </Label>
      )}
      {!disabled ? (
        <>
          <Input
            type={type}
            placeholder={placeholder}
            name={name}
            defaultValue={defaultValue}
            register={register}
            className={classNames(classes.input, inputClassName)}
            {...rest}
          />
          {error?.message && (
            <ErrorMessage>{error?.message?.toString()}</ErrorMessage>
          )}
        </>
      ) : (
        <Input
          type={type}
          value={defaultValue}
          name={name}
          className={classNames(classes.input, inputClassName)}
          disabled
        />
      )}
    </div>
  ) : null;
};

export default FormControl;
