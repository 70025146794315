import { doc, updateDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  Auth,
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";

import UserCredentials from "types/UserCredentials";
import { db } from "../firebase";
import { signIn } from "./AuthService";

const difference = (userData: UserCredentials, data: UserCredentials) => {
  const keys = Object.entries(data);

  const differenciated: [string, string][] = [];

  keys.forEach((entry) => {
    const value = entry[0] as keyof typeof userData;
    if (userData[value] !== entry[1]) {
      differenciated.push([entry[0], entry[1]]);
    }
  });
  return differenciated;
};

const reAutenticate = async (auth: Auth, currentPassword: string) => {
  if (auth.currentUser && auth.currentUser.email) {
    const userSignIn = await signIn(
      auth.currentUser.email,
      currentPassword
    ).catch(() => {});

    if (!userSignIn) {
      throw new Error("Wrong password");
    }

    const credential = EmailAuthProvider.credential(
      auth.currentUser.email,
      currentPassword
    );
    const { user } = await reauthenticateWithCredential(
      auth.currentUser,
      credential
    );

    const token = await user.getIdToken();

    localStorage.setItem("token", token);
  } else {
    throw new Error("Please log in again");
  }
};

const updateUserData = async (user: UserCredentials, data: UserCredentials) => {
  try {
    const minimalDifference = difference(user, data);
    const auth = getAuth();

    if (data.password && data.password?.length < 8) {
      throw new Error(
        "The length of the new password has to be greater than 7"
      );
    }

    if (data.password && !data.currentPassword) {
      throw new Error("Please enter your current password.");
    }

    if (data.currentPassword) {
      await reAutenticate(auth, data.currentPassword).catch((error) => {
        throw new Error(error);
      });
    }

    if (auth.currentUser) {
      const { uid } = auth.currentUser;
      const artistRef = doc(db, "artists", uid);

      if (data.spotify === "") await updateDoc(artistRef, { spotify: "" });
      if (data.appleMusic === "")
        await updateDoc(artistRef, { appleMusic: "" });

      minimalDifference.forEach(async (entry) => {
        if (auth.currentUser && entry[1] && entry[0] !== "currentPassword") {
          if (entry[0] === "password") {
            await updatePassword(auth.currentUser, entry[1]);
          } else {
            await updateDoc(artistRef, { [entry[0]]: entry[1] });
            if (entry[0] === "artistName") {
              const functions = getFunctions();
              const updateFieldsOfTracks = httpsCallable(
                functions,
                "updateTracks"
              );
              updateFieldsOfTracks({
                artistId: uid,
                fields: { artistName: entry[1] },
              });
            }
          }
        }
      });
    }
  } catch (err: unknown) {
    const error = err as Error;
    throw new Error(error.message);
  }
};

export { updateUserData };
