import i18nIsoCountries from "i18n-iso-countries";
// eslint-disable-next-line @typescript-eslint/no-var-requires
i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const countriesAliasList = i18nIsoCountries.getNames("en", {
  select: "official",
});

const countriesList = Object.keys(countriesAliasList).map((country) => {
  const upperCaseCountry = countriesAliasList[country].toUpperCase();
  return {
    value: upperCaseCountry,
    label: upperCaseCountry,
  };
});

export { countriesList };
